//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "SimpleSelectCheckbox",
  data() {
    return {
      list: [],
    };
  },
  props: {
    dataComponent: Array, // Все данные доступные для выбора (id, name)
    reset: Number, // При изменении сбрасывает компонент
    default: Array // Устанавливает выбранные значения из списка dataComponent. На вход [id, id...]
  },
  computed: {},
  watch: {
    dataComponent: function(val){
      this.filterDataCatList(val);
    },
    reset: function(){
      for(let item in this.list){
        this.$set(this.list[item], "checked", false);
      }
      this.emitList();
    },
    default: function(val) {
      for(let keyList in this.list){
        this.$set(this.list[keyList], "checked", false);
      }
      if(val.length){
        for(let keyDef in val){
          for(let keyList in this.list){
            if(this.list[keyList].id == val[keyDef]){
              this.$set(this.list[keyList], "checked", true);
            }
          }
        }
      }
      this.emitList();
    }
  },
  methods: {
    selectAll(){
      for(let item in this.list){
        this.$set(this.list[item], "checked", true);
      }
      this.emitList();
    },
    unselectAll(){
      for(let item in this.list){
        this.$set(this.list[item], "checked", false);
      }
      this.emitList();
    },
    toggleCheckService(itemId){
      for(let item in this.list){
          if(itemId == this.list[item].id){
            this.$set(this.list[item], "checked", !this.list[item].checked);
          }
      }
      this.emitList();
    },
    emitList(){
      let result = [];
      for(let item in this.list){
        if(this.list[item].checked){
          result[result.length] = this.list[item].id;
        }
      }
      this.$emit('changeValue', result);
    },
    filterDataCatList(data){
      this.list = [];
      for(let item in data){
        let obj = {};
        obj.id = data[item].id;
        obj.name = data[item].name;
        obj.checked = false;
        this.list.push(obj);
      }
    }
  },
  created() {}
};
