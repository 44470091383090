//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//




import {maxLength, required, email, minValue} from "vuelidate/lib/validators";
import axios from "axios";
import SimpleSelectCheckbox from "@/components/SimpleSelectCheckbox";

export default {
  name: "EditUser",
  components: {SimpleSelectCheckbox},
  data(){
    return {
      newUser: {
        id: 0,
        email: '',
        username: '',
        password: '',
        phone_number: '',
        franchiseId: '',
        legalId: '',
        servicePointId: '',
        isMasterFranchise: false,
        roles: [],
        permissions: []
      },
      franchises: [],
      legalPersons: [],
      roleDefault: [],
      permissionDefault: [],
      roleList: [],
      permList: [],
      servicesPoints: [],
      submitted: false,
    }
  },
  props: {
    dataUser: Object
  },
  watch: {
    dataUser: function(val){
      this.newUser.id = val.id;
      this.newUser.email = val.email;
      this.newUser.username = val.username;
      this.newUser.phone_number = val.phone_number;
      this.newUser.franchiseId = val.franchise_id;
      this.newUser.isMasterFranchise = +val.is_master_franchise === 1?true:false;
      this.newUser.legalId = val.legal_id;
      this.newUser.servicePointId = val.service_point_id;
      this.roleDefault = [];
      for(let key in val.roles){
        this.roleDefault[this.roleDefault.length] = key;
      }
      this.permissionDefault = [];
      for(let key in val.permissions){
        this.permissionDefault[this.permissionDefault.length] = key;
      }
    }
  },
  computed: {
    legalPersonsComputed(){
      let result = [];
      if(this.newUser.franchiseId !== '' && this.newUser.franchiseId > 0){
        for(let key in this.legalPersons){
          if(+this.legalPersons[key].franchise_id === +this.newUser.franchiseId){
            result[result.length] = this.legalPersons[key];
          }
        }
      }
      return result;
    }
  },
  validations: {
    newUser: {
      email: { required, maxLength: maxLength(250), email },
      username: { required, maxLength: maxLength(250) },
      password: { maxLength: maxLength(250) },
      phone_number: { maxLength: maxLength(25) },
      franchiseId: { required, minValue: minValue(1) },
      legalId: { required, minValue: minValue(0) },
      servicePointId: { required, minValue: minValue(0) }
    }
  },
  methods: {
    submitNewUser(){
      this.submitted = true;
      // stop here if form is invalid
      this.$v.newUser.$touch();
      if(!this.$v.newUser.$invalid){
        let formData = new FormData();
        formData.append("id", this.newUser.id);
        formData.append("email", this.newUser.email);
        formData.append("username", this.newUser.username);
        formData.append("phone_number", this.newUser.phone_number);
        formData.append("password", this.newUser.password);
        formData.append("franchise_id", this.newUser.franchiseId);
        formData.append("legal_id", this.newUser.legalId);
        formData.append("service_point_id", this.newUser.servicePointId);
        formData.append("is_master_franchise", this.newUser.isMasterFranchise);
        formData.append("roles", JSON.stringify(this.newUser.roles));
        formData.append("permissions", JSON.stringify(this.newUser.permissions));

        axios
            .post(`/v1/users/update`, formData)
            .then(() => {
              this.$emit('updateUser');
              this.newUser.password = '';
              this.$store.dispatch("addNotification", {
                text: this.$t('popularWords.successfully'),
                time: 2,
                color: "success"
              });
            })
            .catch(err => {
              this.$store.dispatch("addNotification", {
                text: err.response.data.message,
                time: 6,
                color: "danger"
              });
            });
      }
    },
    changeRoleSelect(val){
      this.newUser.roles = val;
    },
    changePermSelect(val){
      this.newUser.permissions = val;
    },
    preparePermData(val) {
      let result = [];
      for (let key in val) {
        let obj = {};
        obj.id = val[key].name;
        obj.name = val[key].description;
        result.push(obj);
      }
      return result;
    },
    getFranchises(){
      axios
          .get(`/v1/franchise/get-all`)
          .then(resp => {
            this.franchises = resp.data;
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    getLegalPersons(){
      axios
        .get(`/v1/legal-person/get-all`)
        .then(resp => {
          this.legalPersons = resp.data;
        })
        .catch(err => {
          this.$store.dispatch("addNotification", {
            text: err.response.data.message,
            time: 6,
            color: "danger"
          });
        });
    },
    getRoles(){
      axios
          .post(`/v1/role/get-all`)
          .then(resp => {
            this.roleList = this.preparePermData(resp.data.roles);
            this.permList = this.preparePermData(resp.data.perm);
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    getServicesPoints(){
      axios
          .post(`/v1/services-points/get-all`)
          .then(resp => {
            this.servicesPoints = resp.data;
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    }
  },
  created() {
    this.getFranchises();
    this.getRoles();
    this.getLegalPersons();
    this.getServicesPoints();
  }

};
