//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

// import {
//   required,
//   minLength,
//   maxLength
// } from "vuelidate/lib/validators";
import axios from "axios";
import Paginator from "@/components/Paginator";
import Popup from "@/components/Popup";
import NewUser from "@/components/management/users/NewUser";
import EditUser from "@/components/management/users/EditUser";

/**
 * Rbac component
 */
export default {
  components: {
    EditUser,
    NewUser,
    Popup,
    Paginator,
    Layout,
    PageHeader,
  },
  data() {
    return {
      newUserPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
      },
      editUserPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
      },
      deleteUserPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.confirm'),
        actionClass: "btn-success"
      },
      deletedUserId: 0,
      editUser: {},
      submitted: false,
      title: this.$t('menuitems.management.list.users'),
      items: [
        {
          text: this.$t('menuitems.management.text'),
        },
        {
          text: this.$t('menuitems.management.list.users'),
          active: true
        }
      ],
      data: [],
      thisPage: 1, // Текущая страница
      pagesCountPaginator: 0, // Кол-во страниц
      pageSize: 25, // Сколько записей подгружать на 1 страницу
    };
  },
  validations: {

  },
  computed: {

  },
  methods: {
    closeDeleteUserPopup(){
      this.deleteUserPopup.show = false;
    },
    showDeleteUserPopup(id){
      this.deleteUserPopup.show = true;
      this.deletedUserId = id;
    },
    deleteUserPopupSubmit(){
      let formData = new FormData();
      formData.append("id", this.deletedUserId);
      axios
          .post(`/v1/users/delete-user`, formData)
          .then(() => {
            this.closeDeleteUserPopup();
            this.getData();
            this.$store.dispatch("addNotification", {
              text: this.$t('popularWords.deleted'),
              time: 2,
              color: "success"
            });
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    editUserShow(obj){
      this.editUser = obj;
      this.editUserPopup.show = true;
    },
    closeEditUserPopup(){
      this.editUserPopup.show = false;
    },
    userCreated(){
      this.closeNewUserPopup();
      this.getData();
    },
    userUpdated(){
      this.closeEditUserPopup();
      this.getData();
    },
    closeNewUserPopup(){
      this.newUserPopup.show = false;
    },
    showNewUserPopup(){
      this.newUserPopup.show = true;
    },
    getData() {
      let formData = new FormData();
      formData.append("page", this.thisPage);
      formData.append("page_size", this.pageSize);
      axios
          .post(`/v1/users/get-list`, formData)
          .then(resp => {
            this.data = resp.data.data;
            this.pagesCountPaginator = resp.data.pages;
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    setPage(val) {
      this.thisPage = val;
      this.getData();
    }
  },
  created() {
    this.getData();
  }
};
